import React, { useEffect, useState, createContext } from "react";
import LandingPage from "./LandingPage";
import DashboardPage from "./DashboardPage";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { UserContext } from "Common/UserContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/functions";

export const DownloadContext = createContext({});

export const signOut = () => {
  firebase.auth().signOut();
};

function App() {
  const [user, setUser] = useState("loading");
  const [downloads, setDownloads] = useState();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.uid) {
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .onSnapshot((results) => {
            let document = results.data() ?? {};
            let userDetails = { ...user };
            userDetails["uid"] = user.uid;
            if (document.email) {
              userDetails["email"] = user.email;
            }
            if (document.display_name) {
              userDetails["displayName"] = document.display_name;
            }
            if (document.photo_url) {
              userDetails["photoURL"] = document.photo_url;
            }
            userDetails["features"] = document.features;
            userDetails["company"] = document.company;
            userDetails["config"] = document.config ?? {};
            setUser(userDetails);
          });
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    firebase.firestore().collection("downloads").onSnapshot((snapshot) => {
      const items = snapshot.docs.map(x => {
        return {id: x.id, ...x.data()}
      });
      const downloads = {};
      for (const item of items) {
        downloads[item.id] = item;
      }
      setDownloads(downloads);
    });
  }, []);

  return (
    <>
      <UserContext.Provider value={user}>
        <DownloadContext.Provider value={downloads}>
          <Router>
            <Route
              path="/"
              component={(props) =>
                user === "loading" ? (
                  <></>
                ) : user?.uid ? (
                  <>
                    <DashboardPage {...props} />
                  </>
                ) : (
                  <>
                    <LandingPage {...props} />
                  </>
                )
              }
            />
          </Router>
        </DownloadContext.Provider>
      </UserContext.Provider>
    </>
  );
}

export default App;

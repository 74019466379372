import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { BiCaretRight } from "react-icons/bi";

export function FirestoreTeamItem(props) {
  const { teamId, small, className, iconOnly, select } = props;
  const [team, setTeam] = useState();

  useEffect(() => {
    firebase
      .firestore()
      .collection("teams")
      .doc(teamId)
      .onSnapshot((snapshot) => {
        setTeam(snapshot.data());
      });
  }, [teamId]);

  return team ? <TeamItem team={team} small={small} select={select} className={className} iconOnly={iconOnly} /> : <></>;
}

export function TeamItem(props) {
  let label = props.team?.strategies?.length;
  if (label === 0) {
    label = "no strategies";
  } else if (label === 1) {
    label = "one strategy";
  } else {
    label += " strategies";
  }

  const image = (
    <img
      src={props.team.photoURL}
      className={`team-logo-image ${props.iconOnly && "pointer"} ${props.menuItem && "me-4"} ${props.className}`}
      style={
        props.iconOnly
          ? {
              width: props.menuItem ? "26px" : "22px",
              height: props.menuItem ? "26px" : "22px",
              borderRadius: "2px",
              marginRight: !props.iconOnly && "5px",
              display: "inline",
            }
          : {
              marginRight: !props.menuItem && "0.25em",
              width: props.menuItem && "26px",
              height: props.menuItem && "26px",
            }
      }
      onClick={() => {
        if (props.iconOnly && props.select) {
          props.select();
        }
      }}
    />
  );
  const description = (
    <div className="d-flex flex-column justify-content-between flex-grow-1 me-1">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div
          className="team-name small"
          style={{
            color: props.menuItem && (props.active ? "white" : "#ccc"),
            fontWeight: props.menuItem && (props.active ? "800" : "400")
          }}
        >
          {props.team.name}
        </div>
        {props.menuItem && props.active && <BiCaretRight />}
      </div>
      <div className="team-strategies d-none small">{`Team sharing ${label}`}</div>
    </div>
  );

  if (props.iconOnly) {
    return image;
  }

  return (
    <ListGroup.Item
      key={props.team.id}
      className={
        "team-list-item" + (props.small ? " small" : "") + (props.menuItem ? " menu-item" : "")
      }
      variant={props.menuItem ? "none" : "light"}
      onClick={() => {
        props.select();
      }}
      active={props.active}
      action
    >
      <div className="d-flex flex-row justify-content-start align-items-center">
        {image}
        {description}
      </div>
    </ListGroup.Item>
  );
}

import { signOut } from "App";
import { FirestoreTeamItem } from "./TeamItem";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { BiCheckCircle, BiCircle } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { IoWarning } from "react-icons/io5";
import { UserContext } from "./UserContext";
import { UserItem } from "./UserItem";

function Feature(props) {
  const { on, onTitle, offTitle } = props;
  return on ? (
    <div className="d-flex align-items-center">
      <BiCheckCircle style={{ color: "green" }} className="me-1" />
      <div>{onTitle}</div>
    </div>
  ) : (
    <div className="d-flex align-items-center" style={{ color: "#888" }}>
      <BiCircle className="me-1" />
      <div>{offTitle}</div>
    </div>
  );
}

export function Features(props) {
  const { longTerm, flagship, advancedFlagship, strategyEditor } = props;
  return (
    <>
      <Feature
        onTitle={
          <>
            Subscribed to <strong>Simple Investments</strong>
          </>
        }
        offTitle={<>Not subscribed to Simple Investments</>}
        on={longTerm}
      />
      <Feature
        onTitle={
          <>
            Subscribed to <strong>Flagship Strategies</strong>
          </>
        }
        offTitle={<>Not subscribed to Flagship Strategies</>}
        on={flagship}
      />
      <Feature
        onTitle={
          <>
            Subscribed to <strong>Advanced Strategies</strong>
          </>
        }
        offTitle={<>Not subscribed to Advanced Strategies</>}
        on={advancedFlagship}
      />
      <Feature
        onTitle={<>Strategy editor enabled</>}
        offTitle={<>Strategy editor not enabled</>}
        on={strategyEditor}
      />
      <Feature
        onTitle={<>Custom block editor enabled</>}
        offTitle={<>Custom block editor not enabled</>}
        on={strategyEditor}
      />
    </>
  );
}

export function UserSettings(props) {
  const { show, close } = props;

  const [name, setName] = useState();
  const [strategyCreator, setStrategyCreator] = useState();

  const save = () => {
    let data = {};
    // data.config.experience_level = experienceLevel;
    // data.config.accredited = accredited;
    data.display_name = name;
    data["config.strategy_creator"] = strategyCreator ?? false;
    firebase.firestore().collection("users").doc(user.id).update(data).then(() => {
      close();
    });
  }

  // const [step, setStep] = useState(0);
  // const [experienceLevel, setExperienceLevel] = useState();
  // const [accredited, setAccredited] = useState(false);

  const user = useContext(UserContext);
  useEffect(() => {
    if (!user?.displayName) {
      return;
    }

    setName(user.displayName);
    setStrategyCreator(user?.config?.strategy_creator);
    // setStep(0);
    // setExperienceLevel(user?.config?.experience_level);
    // setAccredited(user?.config?.accredited);
  }, [show, user, user?.config?.strategy_creator, user?.displayName]);

  return (
    <Modal show={show} size="sm">
      <Modal.Header className="d-flex align-items-baseline">
        <h5 className="mb-0">Account Setup</h5>
        <CgClose className="pointer" onClick={close} />
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <UserItem id={user.id} />
        </div>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "500" }}>Name</Form.Label>
            <Form.Control value={name} onChange={(event) => {
              setName(event.target.value);
            }} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "500" }}>Email</Form.Label>
            <Form.Control value={user.email} disabled />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "500" }}>Company</Form.Label>
            <div style={{ pointerEvents: "none" }}>
              {user.company ? <FirestoreTeamItem teamId={user.company} small /> : <>Personal Account</>}
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "500" }}>Roles</Form.Label>
            <div className="mb-2">
              <div className="d-flex">
                <Form.Check checked disabled />
                <div>I need to run backtests</div>
              </div>
            </div>
            <div className="mb-2">
              <div className="d-flex">
                <Form.Check checked={strategyCreator} onChange={event => setStrategyCreator(event.target.checked)} />
                <div>I need to create strategies</div>
              </div>
              <div className="text-muted small d-flex align-items-center"><IoWarning className="me-1" /> Advanced users only</div>
              <div className="text-muted small">Enables the strategy editor</div>
              <div className="text-muted small">Enables the custom block editor</div>
            </div>
          </Form.Group>
        </Form>
        {/*<div className="settings">
          {step === 0 && (
            <>
              <div className="mb-3">
                <p>Please answer a few questions about your financial experience.</p>
                <p>
                  <small>You can change your answers later if you&lsquo;d like.</small>
                </p>
              </div>
              <HR className="mb-3" />
              <p className="mb-3">
                <Bouncer direction="right" className="me-1" />{" "}
                <strong>How experienced are you with investing?</strong>
              </p>
              <div className="mb-5">
                <Form.Check
                  type="radio"
                  label="I'm just starting to learn about the markets"
                  name="experience_level"
                  checked={experienceLevel === 0}
                  onChange={() => setExperienceLevel(0)}
                />
                <Form.Check
                  type="radio"
                  label="I'm somewhat experienced and have a portfolio already"
                  name="experience_level"
                  checked={experienceLevel === 1}
                  onChange={() => setExperienceLevel(1)}
                />
                <Form.Check
                  type="radio"
                  label="I'm experienced and actively trade my book"
                  name="experience_level"
                  checked={experienceLevel === 2}
                  onChange={() => setExperienceLevel(2)}
                />
                <Form.Check
                  type="radio"
                  label="I'm a professional trader or salesperson"
                  name="experience_level"
                  checked={experienceLevel === 3}
                  onChange={() => setExperienceLevel(3)}
                />
              </div>
            </>
          )}
          {(step === 1 || step === 2) && (
            <div>
              <div className="mb-3">
                {experienceLevel === 0 && (
                  <div>
                    <p className="d-flex align-items-center">
                      <strong>
                        <BiCheckCircle style={{ color: "green" }} /> You&lsquo;re new to investing.
                        You&lsquo;ll learn a lot!
                      </strong>
                    </p>
                    <p>
                      We&lsquo;ll start you off with simple but powerful strategies that don&lsquo;t
                      require much experience to understand.
                    </p>
                    <p>
                      <Features longTerm flagship />
                    </p>
                  </div>
                )}
                {experienceLevel === 1 && (
                  <div>
                    <p className="d-flex align-items-center">
                      <strong>
                        <BiCheckCircle style={{ color: "green" }} /> You have some investing
                        experience.
                      </strong>
                    </p>
                    <p>
                      We&lsquo;ll start you off with simple but powerful strategies that we created.
                      You can play with the parameters to your liking.
                    </p>
                    <p>
                      <Features longTerm flagship advancedFlagship />
                    </p>
                  </div>
                )}
                {experienceLevel === 2 && (
                  <div>
                    <p className="d-flex align-items-center">
                      <strong>
                        <BiCheckCircle style={{ color: "green" }} /> You&lsquo;re an experienced
                        trader.
                      </strong>
                    </p>
                    <p>
                      We&lsquo;ll give you access to the strategy creation tools and all of the
                      strategies that we build.
                    </p>
                    <p>
                      <Features longTerm flagship advancedFlagship strategyEditor />
                    </p>
                  </div>
                )}
                {step === 1 && experienceLevel === 3 && (
                  <div>
                    <p>
                      CloudStrat is the best way to create, backtest, and deploy systematic trading
                      strategies. It was founded by a team of traders and engineers formerly from
                      Goldman Sachs.
                    </p>
                    <p>
                      <strong>CloudStrat was built for professionals.</strong> Please email{" "}
                      <a href="mailto:contact@cloudstrat.com">contact@cloudstrat.com</a> to discuss
                      a site license to:
                    </p>
                    <p>
                      <Feature on onTitle={<>Create custom blocks with Python</>} />
                      <Feature on onTitle={<>Integrate your data to use in backtests</>} />
                      <Feature on onTitle={<>Sweep through parameters for backtests</>} />
                      <Feature on onTitle={<>Hook into your risk or compliance system</>} />
                      <Feature on onTitle={<>Enable strategy collaboration features</>} />
                      <Feature on onTitle={<>Share strategies with your clients</>} />
                      <Feature on onTitle={<>Let clients execute strategies through you</>} />
                    </p>
                    <p>
                      In the meantime, we&lsquo;ll give you access to the strategy creation tools
                      and all of the strategies that we build.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {step === 1 && experienceLevel !== 3 && (
            <>
              <HR className="mb-3" />
              <p>
                <Bouncer direction="right" /> <strong>Are you an accredited investor?</strong>
                <br />
                <small className="text-muted">
                  You make &gt;$200k/year or your net worth is &gt;$1M
                </small>
              </p>
              <div className="mb-5">
                <Form.Check type="radio" label="Not yet" name="accredited_investor" checked={accredited === false} onChange={() => setAccredited(false)} />
                <Form.Check type="radio" label="Of course" name="accredited_investor" checked={accredited === true} onChange={() => setAccredited(true)} />
              </div>
            </>
          )}
          {step === 2 && <>
            <HR className="mb-3" />
            <p className="d-flex align-items-center">
              {accredited && <strong>
                <BiCheckCircle style={{ color: "green" }} /> You&lsquo;re an accredited investor.
              </strong>}
              {accredited === false && <strong>
                <BiCircle /> You&lsquo;re not an accredited investor, yet.
              </strong>}
            </p>
            {accredited && <p>
              You&lsquo;ll be able to invest in <strong>Simple Investments</strong> and <strong>CloudStrat Flagship Strategies</strong>.
            </p>}
            {accredited === false && <p>
              You can test strategy ideas, but unfortunately, you can&lsquo;t invest in them until you become an accredited investor.
            </p>}
            {experienceLevel === 2 && accredited && <p>
              If you&lsquo;d like to invest in strategies that you create yourself, please email us at <a href="mailto:contact@cloudstrat.com">contact@cloudstrat.com</a>.
            </p>}
          </>}
        </div>*/}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={() => save()}>Save</Button>
        <Button variant="danger" onClick={() => { signOut(); }}>Log Out</Button>
      </Modal.Footer>
      {/*<Modal.Footer>
        {step > 0 ? (
          <Button variant="secondary" onClick={() => setStep(step - 1)}>
            Back
          </Button>
        ) : (
          <Button variant="secondary" onClick={() => {
            signOut();
          }}>Log Out</Button>
        )}
        {(step === 0 || (step < 2 && experienceLevel < 3)) && <Button disabled={experienceLevel === undefined} onClick={() => setStep(step + 1)}>Next</Button>}
        {((step === 1 && experienceLevel === 3) || step === 2) && <Button onClick={() => save()}>Save</Button>}
        </Modal.Footer>*/}
    </Modal>
  );
}

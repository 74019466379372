import React, { useContext, useState } from "react";
import { IoIosHelpBuoy } from "react-icons/io";
import { IconButton } from "./Common/IconButton";
import { Logo } from "./Common/Logo";
import { UserContext } from "./Common/UserContext";
import { UserItem } from "./Common/UserItem";
import { UserSettings } from "./Common/UserSettings";

function Header(props) {
  const { showButtons, onClick, className, logo } = props;

  const user = useContext(UserContext);

  const [helpVisible, setHelpVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);

  return (
    <>
      <header className={`Header navbar navbar-dark bg-dark ${className}`} style={{ position: "sticky" }}>
        {logo || <Logo className="header-item" onClick={onClick} />}
        <div className="d-flex align-items-center h-100">
          {showButtons && (
            <div className="d-none d-md-flex">
              <IconButton
                icon={<IoIosHelpBuoy />}
                label="Help"
                variant="warning"
                size="sm"
                className="me-3"
                onClick={() => setHelpVisible(true)}
              />
            </div>
          )}
          <div className="header-menu header-item d-flex flex-row align-items-center">
            {user ? (
              <>
                <div className="d-none d-lg-flex pointer" onClick={() => setSettingsVisible(true)}><UserItem id={user?.uid} dark /></div>
                <div className="d-flex d-lg-none pointer" onClick={() => setSettingsVisible(true)}><UserItem id={user?.uid} dark compact /></div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </header>
      <UserSettings show={settingsVisible} close={() => setSettingsVisible(false)} />
    </>
  );
}

export default Header;

import React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "Common/index.css";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/compat/app";
import { ToastContainer } from "react-toastify";
import "index.css";

document.addEventListener('DOMContentLoaded', function () {
  var firebaseConfig = {
    apiKey: "AIzaSyDAoKaI9MieIT4aMc5EdyHroLaYYdWnYu0",
    authDomain: "cloudstrat-4b454.firebaseapp.com",
    databaseURL: "https://cloudstrat-4b454-default-rtdb.firebaseio.com",
    projectId: "cloudstrat-4b454",
    storageBucket: "cloudstrat-4b454.appspot.com",
    messagingSenderId: "402669595877",
    appId: "1:402669595877:web:4920a642d3df1a53e2380c",
    measurementId: "G-2QRQZWL350",
  };

  firebase.initializeApp(firebaseConfig);

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <>
      <App />
      <ToastContainer autoClose={2000} />
    </>
  );
});

import { ColumnLayout } from "Common/ColumnLayout";
import { UserItem } from "Common/UserItem";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export function MetricsDashboardPage() {
    const [users, setUsers] = useState();
    const [userInfos, setUserInfos] = useState();
    const [usageData, setUsageData] = useState();

    useEffect(() => {
        firebase.firestore().collection("users").onSnapshot((snapshot) => {
            let userInfos = {};
            for (const doc of snapshot.docs) {
                userInfos[doc.id] = doc.data();
            }
            setUsers(snapshot.docs.map(x => {
                return { id: x.id, ...x.data() };
            }));
            setUserInfos(userInfos);
        });
    }, []);

    useEffect(() => {
        firebase.database().ref("/metrics").on("value", (snapshot) => {
            const data = snapshot.val();
            const eventNames = ["strategies", "backtests", "blocks"];
            let allData = {};
            for (const eventName of eventNames) {
                const events = Object.values(data[eventName]);
                let start = new Date(2022, 11, 1).getTime();
                let buckets = [];
                let bucketEnd = start;
                let counts = {};
                let users = {};
                let bucketStart = start;
                bucketEnd += 1000 * 60 * 60 * 24;
                for (const x of events) {
                    const user = userInfos && x.userId in userInfos ? userInfos[x.userId].display_name ?? userInfos[x.userId].email : x.userId;
                    if (x.timestamp < bucketEnd) {
                        if (!(user in users)) {
                            users[user] = true;
                        }
                        if (!(user in counts)) {
                            counts[user] = 0;
                        }
                        counts[user] += 1;
                    } else {
                        buckets.push({
                            date: new Date(bucketStart).toLocaleDateString(),
                            ...counts
                        });
                        counts = {};
                        if (!(user in counts)) {
                            counts[user] = 0;
                        }
                        counts[user] += 1;
                        bucketEnd += 1000 * 60 * 60 * 24;
                        bucketStart += 1000 * 60 * 60 * 24;
                    }
                }
                if (buckets) {
                    buckets.push({
                        date: new Date(bucketStart).toLocaleDateString(),
                        ...counts
                    });
                }
                allData[eventName] = {
                    data: buckets,
                    users: users
                };
            }
            setUsageData(allData);
        });
    }, [userInfos]);

    return (
        <ColumnLayout widths={[null]} content={[
            <Container className="h-100 w-100 px-5 pt-3">
                <Row className="h-100">
                    <Col xs={4} className="h-100">
                        <Card className="h-100">
                            <Card.Header>
                                <div style={{ fontWeight: "500" }}>Users</div>
                            </Card.Header>
                            <Card.Body className="d-flex flex-column flex-grow-1" style={{ height: "0" }}>
                                <ListGroup className="flex-grow-1 overflow-auto">
                                    {users?.map(user => <UserItem key={user.id} id={user.id} size={50} />)}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="h-100">
                            <Card.Header>
                                <div style={{ fontWeight: "500" }}>Usage Metrics</div>
                            </Card.Header>
                            <Card.Body>
                                <h6 className="mb-3">Backtests</h6>
                                <ResponsiveContainer width="100%" height={300} className="mb-3">
                                    <BarChart width={400} height={300} data={usageData?.backtests.data}>
                                        <CartesianGrid />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        {Object.keys(usageData?.backtests?.users ?? {}).map(x => <Bar key={x} dataKey={x} stackId="users" />)}
                                    </BarChart>
                                </ResponsiveContainer>
                                <h6 className="mb-3">Strategies</h6>
                                <ResponsiveContainer className="mb-3" width="100%" height={300}>
                                    <BarChart width={400} height={300} data={usageData?.strategies.data}>
                                        <CartesianGrid />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        {Object.keys(usageData?.strategies?.users ?? {}).map(x => <Bar key={x} dataKey={x} stackId="users" />)}
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>]}
        />
    );
}
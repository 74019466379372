import { Logo } from "Common/Logo";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import { MetricsDashboardPage } from "MetricsDashboardPage";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Container, ListGroup, Row } from "react-bootstrap";
import { AiOutlineHome } from "react-icons/ai";
import { BiCaretRight, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { FaChartLine } from "react-icons/fa";
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import Header from "./Header";
import { UserContext } from "./Common/UserContext";
import logo from "./images/common/logo.png";

const METRICS = "metrics";

function MenuItem(props) {
    const { setSelectedItemAndCollapse, selectedItemType, itemType, icon, label } = props;

    const highlighted = !selectedItemType
        ? itemType === null || itemType === undefined
        : selectedItemType === itemType;

    return (
        <ListGroup.Item
            variant="none"
            className={`menu-item ps-1 pe-0 py-1 ${highlighted && "highlighted"}`}
            onClick={() => {
                if (itemType) {
                    setSelectedItemAndCollapse([itemType, ""]);
                } else {
                    setSelectedItemAndCollapse(null);
                }
            }}
            style={{ marginLeft: "0.18em" }}
        >
            <div className="d-flex flex-row align-items-center justify-content-start pointer">
                <div
                    className="me-3"
                    style={{ width: "27px", fontSize: "large", color: highlighted ? "white" : "#ccc" }}
                >
                    {icon}
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div>{label}</div>
                    {highlighted && <BiCaretRight />}
                </div>
            </div>
        </ListGroup.Item>
    );
}

function DashboardSidebar(props) {
    const { itemType/*, itemId*/, setSelectedItem, collapsed, setCollapsed, contentHidden } = props;
    const setSelectedItemAndCollapse = (selection) => {
        setSelectedItem(selection);
        onResize();
    };
    const user = useContext(UserContext);
    const [/*userInfo*/, setUserInfo] = useState();

    const onResize = useCallback(() => {
        if (window.innerWidth < 901) {
            setCollapsed(true);
        }
    }, [setCollapsed]);

    useEffect(() => {
        if (user && user.uid) {
            firebase
                .firestore()
                .collection("users")
                .doc(user.uid)
                .onSnapshot((snapshot) => {
                    setUserInfo({ uid: user.uid, ...snapshot.data() });
                });
        }
    }, [user]);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [onResize]);

    useEffect(() => {
        onResize();
    }, [onResize]);

    return (
        <div
            className="d-flex d-flex-row bg-dark sidebar flex-column"
            style={{ width: "320px", marginLeft: collapsed && "-315px" }}
        >
            <div
                style={{
                    width: "20px",
                    height: "30px",
                    borderColor: "transparent",
                    borderRadius: "0 12px 12px 0",
                    position: "absolute",
                    top: "12px",
                    right: "-7px",
                    cursor: "pointer",
                    zIndex: "1000",
                    overflowY: "auto",
                }}
                className="bg-dark text-light"
                onClick={() => setCollapsed(!collapsed)}
            >
                {collapsed ? (
                    <BiChevronRight className="w-100 h-100 text-light" />
                ) : (
                    <BiChevronLeft className="w-100 h-100 text-light" />
                )}
            </div>
            <section className="mb-4 d-flex flex-column justify-content-between" style={{ opacity: contentHidden ? "0" : "1" }}>
                <MenuItem
                    icon={<AiOutlineHome />}
                    label={"Home"}
                    itemType={null}
                    selectedItemType={itemType}
                    setSelectedItemAndCollapse={setSelectedItemAndCollapse}
                />
                <MenuItem
                    icon={<FaChartLine />}
                    label={"Metrics"}
                    itemType={METRICS}
                    selectedItemType={itemType}
                    setSelectedItemAndCollapse={setSelectedItemAndCollapse}
                />
            </section>
        </div>
    );
}

function DashboardContent(props) {
    return (
        <div
            className="dashboard-content h-100 p-0 flex-grow-1"
            style={{ width: 0, position: "relative" }}
        >
            {props.children}
            <div className="panel-shadow"></div>
        </div>
    );
}

function Dashboard(props) {
    const { setSelectedItem/*, user*/, sidebarCollapsed, setSidebarCollapsed } = props;
    const [sidebarContentHidden, /*setSidebarContentHidden*/] = useState(false);
    const { itemType, itemId, /*subItem*/ } = useParams();

    let dashboardContent = (
        <DashboardContent>
            <MetricsDashboardPage />
        </DashboardContent>
    );

    return dashboardContent;
}

function DashboardPage() {
    const user = useContext(UserContext);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const history = useHistory();
    const { url } = useRouteMatch();

    const setSelectedItem = (item) => {
        if (!item) {
            history.push(`${url}`);
        } else {
            history.push(url + `${item[0]}/${item[1]}`);
        }
    };

    const body = (
        <Dashboard
            setSelectedItem={setSelectedItem}
            user={user}
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
        />
    );

    return (
        <>
            <Header logo={<div className="align-items-center h-100 logo d-flex"><Logo className="h-100 me-3" />
                <span className="title" style={{ fontWeight: "400" }}>
                    Corp
                </span>
</div>} className="d-none d-sm-flex" />
            <Header onClick={() => setSidebarCollapsed(!sidebarCollapsed)} className="d-flex d-sm-none" />
            <Container
                fluid
                className="dashboard bg-light d-flex flex-column flex-grow-1"
                style={{ background: "url(" + logo + ")", backgroundSize: "30px" }}
            >
                <Row className="d-flex flex-row h-100">
                    <Route path="/:itemType?/:itemId?/:subItem?">{body}</Route>
                </Row>
            </Container>
        </>
    );
}

export default DashboardPage;
